import LogoIcon from "!@svgr/webpack!../images/logo.svg?react";
import LogoIconWhite from "!@svgr/webpack!../images/logowhite.svg?react";
import Drawer from "@goalteller/app-kit/components/SideDrawer/Drawer";
import analytics from "@goalteller/app-kit/tools/analytics";
import { useThemeState } from "@goalteller/app-kit/wo-library/contexts/ThemeContext";
import { Anchor, Button } from "@goalteller/app-kit/ye-design/components/atoms";
import {
  TopNavItem,
  TopNav as YeTopNav,
} from "@goalteller/app-kit/ye-design/components/structures";
import {
  useLayoutMethods,
  useLayoutState,
} from "@goalteller/app-kit/ye-design/contexts/LayoutContext";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { type ComponentProps } from "react";

import { DARK_PAGES } from "../data/constants";
import actionArrow from "../images/tiltArrow.svg";
import { GradientButton } from "../wrappers/button";
import styles from "./topNav.module.css";

const ANALYTICS_POSITION = "top navigation";

function TopNavAnchor(props: ComponentProps<typeof Anchor>) {
  const {
    dispatch: { updateTopNav },
  } = useLayoutMethods();

  return (
    <Anchor
      as={Link}
      onClick={() => {
        analytics.track("Navigation", {
          position: ANALYTICS_POSITION,
          text: props.children,
        });
        updateTopNav({
          isDrawerToggled: false,
        });
      }}
      prefetch={false}
      size="small"
      {...props}
    />
  );
}

function Logo({
  isCorporate,
  isDarkTheme,
}: {
  isCorporate: boolean;
  isDarkTheme: boolean;
}) {
  const { pathname } = useRouter();

  return (
    <Link
      className={styles.logo}
      href={isCorporate ? pathname : "/"}
      onClick={() => {
        analytics.track("Navigation", {
          position: ANALYTICS_POSITION,
          text: "logo",
        });
      }}
      prefetch={false}
    >
      {isDarkTheme ? (
        <LogoIconWhite className={styles.logoImage} />
      ) : (
        <LogoIcon className={styles.logoImage} />
      )}
    </Link>
  );
}

function SignInButton({ isDarkTheme }: { isDarkTheme: boolean }) {
  return isDarkTheme ? (
    <Button
      className={styles.signInBtn}
      onClick={() => {
        window.open(
          `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/login`,
          "_blank",
        );
      }}
      theme="dark"
    >
      Sign In
    </Button>
  ) : (
    <GradientButton
      onClick={() => {
        window.open(
          `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/login`,
          "_blank",
        );
      }}
    >
      Sign In{"  "}
      <span className={styles.arrowIcon}>
        <Image alt="arrow" src={actionArrow} />
      </span>
    </GradientButton>
  );
}

function ContentLeft({ isDarkTheme }: { isDarkTheme: boolean }) {
  const router = useRouter();

  return (
    <div className={styles.rightWrapper}>
      <div className={styles.menu}>
        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/",
            })}
            href="/"
          >
            Home
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/about",
            })}
            href="/about"
          >
            About us
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/features",
            })}
            href="/features"
          >
            Free Tools
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem className={styles.menuWrapper}>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/features",
            })}
            href="/product/explorer"
          >
            Product
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/pricing-plan",
            })}
            href="/pricing-plan"
          >
            Pricing
          </TopNavAnchor>
        </TopNavItem>

        <TopNavItem>
          <TopNavAnchor
            className={clsx(isDarkTheme ? styles.linkDarkTheme : styles.link, {
              [styles.activeTab]: router.pathname === "/blog",
            })}
            href="/blog"
          >
            Blog
          </TopNavAnchor>
        </TopNavItem>
      </div>
    </div>
  );
}

function CoporateContentRight() {
  const { pathname } = useRouter();

  return (
    <div className={styles.right}>
      <Link
        className={styles.hashLink}
        href={`${pathname}#home`}
        prefetch={false}
        scroll={false}
      >
        Home
      </Link>
      <Link
        className={styles.hashLink}
        href={`${pathname}#about`}
        prefetch={false}
        scroll={false}
      >
        About Us
      </Link>
      <Link
        className={styles.hashLink}
        href={`${pathname}#pricing`}
        prefetch={false}
        scroll={false}
      >
        Pricing
      </Link>
      <Link
        className={clsx(styles.button, styles.hashButton)}
        href={`${pathname}#email`}
        prefetch={false}
        scroll={false}
      >
        Free Trial
      </Link>
    </div>
  );
}

export default function TopNav({ ...props }) {
  const { pathname } = useRouter();
  const isCorporate = pathname.includes("corporate");
  const {
    topNav: { isDrawerToggled },
  } = useLayoutState();

  const { dispatch: layoutMethods } = useLayoutMethods();
  const { activeTheme } = useThemeState();

  const isDarkTheme =
    pathname === "/" || (DARK_PAGES.has(pathname) && activeTheme === "dark");

  return (
    <>
      <YeTopNav
        className={clsx(styles.root)}
        contentMiddle={<ContentLeft isDarkTheme={isDarkTheme} />}
        contentRight={
          isCorporate ? (
            <CoporateContentRight />
          ) : (
            <SignInButton isDarkTheme={isDarkTheme} />
          )
        }
        innerClassNames={{
          container: clsx(
            isDarkTheme ? styles.containerDark : styles.container,
          ),
          contentMenu: clsx(
            isDarkTheme ? styles.contentMenuDark : styles.contentMenu,
          ),
          isExpanded: styles.isExpanded,
        }}
        logo={<Logo isCorporate={isCorporate} isDarkTheme={isDarkTheme} />}
        onRightIconClick={() => {
          layoutMethods.updateTopNav({
            isDrawerToggled: true,
          });
        }}
        sticky={{ hideOnScroll: false, shrinkOffset: 100 }}
        variant="flat"
        {...props}
      />

      <Drawer
        innerClassNames={{
          closeIcon: clsx(
            isDarkTheme ? styles.darkCloseIcon : styles.closeIcon,
          ),
          drawerContainer: clsx(
            isDarkTheme ? styles.darkDrawerContainer : styles.drawerContainer,
          ),
        }}
        isLocked={false}
        isVisible={!!isDrawerToggled}
        label={<Logo isCorporate={isCorporate} isDarkTheme={isDarkTheme} />}
        onClose={() => {
          layoutMethods.updateTopNav({
            isDrawerToggled: false,
          });
        }}
      >
        <div className={styles.drawerContents}>
          <ContentLeft isDarkTheme={isDarkTheme} />
          <SignInButton isDarkTheme={isDarkTheme} />
        </div>
      </Drawer>
    </>
  );
}
