/* jscpd:ignore-start */
import "@goalteller/app-kit/config/polyfills";

import "../styles/globals.css";
// above imports have to be in order

import type { AppProps } from "next/app";

import HeadContent from "@goalteller/app-kit/pages/HeadContent";
import NextAppWrapper from "@goalteller/app-kit/pages/NextAppWrapper";
import { Page } from "@goalteller/app-kit/ye-design/components/structures";
import { LayoutProvider } from "@goalteller/app-kit/ye-design/contexts/LayoutContext";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { Outfit } from "next/font/google";
import localFont from "next/font/local";
import Head from "next/head";
import Script from "next/script";

import AppWrapper from "../components/AppWrapper";
import TopNav from "../components/TopNav";
import "../integrations";
import { lazyTillView } from "../wrappers/LazyTillView";

const FooterDark = lazyTillView(
  dynamic(import("../components/HomePage/FooterDark")),
);

/* jscpd:ignore-end */

const bodyFont = Outfit({
  display: "swap",
  subsets: ["latin"],
  variable: "--ye-font-family-body",
});

const headingFont = localFont({
  display: "swap",
  src: "../../public/fonts/ClashGrotesk-Variable.woff2",
  variable: "--font-clash-grotesk",
});

const nonce = "5b8d4f8d-0f5b-4f8d-8d0f-5b8d4f8d0f5b";

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App({ Component, pageProps }: AppProps) {
  // TODO: implement google analytics using gtag from example at
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        :root {
          --ye-font-family-body: ${bodyFont.style.fontFamily}, apple-system,
            BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
            Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
        h1,
        h2,
        h3 {
          font-family: ${headingFont.style.fontFamily};
        }
        h4,
        h5,
        h6,
        p {
          font-family: ${bodyFont.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta
          content="49ztp3OHvrz6-B-Nuf0NkgPX0Tg_oUxrBmBkuGcLCsM"
          name="google-site-verification"
        />
        <HeadContent
          discription="GoalTeller aids you in planning for yours and your loved ones future. Make the best investment decisions that help you achieve your goals"
          env={process.env.NEXT_PUBLIC_ENV}
          siteDomain="goalteller.com"
          siteName="GoalTeller"
          title="Goalteller - Financial planning like never before"
        />
      </Head>

      {/* zoho salesiq */}
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script id="zsiqchat" nonce={nonce} strategy="lazyOnload">
          {`var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: "siq96ce3bc115aa7dda2c6b9eadf969c92a92c33d8d60a0e491ea6290577933d66e",
            values:{},
            ready:function(){}
          };
          $zoho.salesiq.nonce=${nonce};
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="https://salesiq.zohopublic.in/widget";
          s.nonce=${nonce};
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);`}
        </Script>
      )}

      {/* ms clarity */}
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script strategy="lazyOnload">
          {`(function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i + "?ref=gtm2";
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(
          window,
          document,
          "clarity",
          "script",
          ${process.env.NEXT_PUBLIC_ENV} === "production" ? "elap0n1a2d" : "iqyvqfctjl"
        );`}
        </Script>
      )}

      {/* Zoho - google ads */}
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script
          src="https://crmplus.zoho.in/crm/javascript/zcga.js"
          strategy="lazyOnload"
        />
      )}

      {/* Facebook pixel */}
      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script strategy="lazyOnload">
          {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '220234537685623');
        fbq('track', 'PageView');`}
        </Script>
      )}
      {/* End Google Tag Manager */}
      <NextAppWrapper storeVersion={1.2}>
        <LayoutProvider>
          <AppWrapper>
            <div className={clsx(bodyFont.variable)}>
              <Page topNav={<TopNav />}>
                <Component {...pageProps} />
                <FooterDark />
              </Page>
            </div>
          </AppWrapper>
        </LayoutProvider>
      </NextAppWrapper>
    </>
  );
}
