export const ADDRESS =
  "Finteller Advisors Private Limited, 4th Floor, Novel Office, Novel MSR Building, Marathahalli, Bangalore, Karnataka - 560037";

export const WARNING_MESSAGE =
  "We do not deal in option trading or equity advisory. Please DO NOT believe anyone claiming to sell such packages claiming to be from GoalTeller or its associates/directors or employees";

export const DARK_PAGES = new Set([
  "/about",
  "/features",
  "/pricing-plan",
  "/product/explorer",
  "/product/schemes/fund",
  "/light",
]);
